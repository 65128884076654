<template>
  <div class="workorder">
    <div class="title">
      <p class="c-box">
        <i class="el-icon-house" />
        <span @click="goto('/commodity')">首页</span> > <span class="">财务管理</span>
      </p>
    </div>
    <div class="search">
      <div class="c-box">
        <el-form :inline="true" :model="searchData" class="demo-form-inline">
          <el-row>
            <el-col :span="6">
              <el-form-item label="工单编号">
                <el-input v-model="searchData.workNo" placeholder="请输入" height="36px" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="提交日期：" clearable>
                <el-date-picker
                  v-model="searchData.time"
                  style="width:250px"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="关键字">
                <el-input v-model="searchData.keyword" placeholder="请输入" height="36px" clearable />
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" round icon="el-icon-search" @click="findByPage">查询</el-button>
              <el-button type="success" round icon="el-icon-edit" @click="woOpen('提交工单','add')">提交工单</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="c-box">
      <div class="content">
        <el-table
          class="freightRate-table"
          :data="tableData"
          height="500"
          style="width: 100%;"
        >
          <el-table-column prop="workNo" label="工单编号" align="center" />
          <el-table-column prop="problemDescription" label="问题内容" align="center" />
          <el-table-column prop="fileUrls" label="附件" align="center">
            <template slot-scope="scope">
              <span v-for="(item,i) in scope.row.fileUrls" :key="i" style="margin-right:10px">
                <a :href="item.absolutePath" target="_blank">{{ item.fileName }}</a>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="customerAccount" label="提交账号" align="center" />
          <el-table-column prop="status" label="状态" align="center" width="100px">
            <template slot-scope="scope" z>
              <span v-if="scope.row.status==1">进行中</span>
              <span v-if="scope.row.status==2">已结单</span>
              <span v-if="scope.row.status==3">待评价</span>
              <span v-if="scope.row.status==4">已关闭</span>
            </template>
          </el-table-column>
          <el-table-column label="工单类型" align="center" width="100px">
            <template slot-scope="scope">
              <span v-if="scope.row.priority==1">重要工单</span>
              <span v-if="scope.row.priority==2">普通工单</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180px"
          >
            <template slot-scope="scope">
              <span class="cursor" @click="editOrder(scope.row)">查看</span>
              <span class="cursor" @click="deleteL(scope.row)">删除</span>
              <span />
              <span class="cursor" @click="dialogVisible=true">评价</span>
              <span />
              <span class="cursor" @click="closeL(scope.row)">关闭</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fenye"
          background
          layout="prev, pager, next"
          :current-page="searchData.pageNum"
          :page-size="searchData.pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        />
        <el-dialog
          title="评价"
          :visible.sync="dialogVisible"
          class="dialog_25"
          :before-close="handleClose"
        >
          <el-form ref="formI" :model="formI" :rules="rulesi" label-width="80px">
            <el-form-item label="用户评价" prop="customerEvaluation">
              <el-input v-model="formI.customerEvaluation" type="textarea" class="minh-111" />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="success" round style="min-width:100px;">提交</el-button>
          </div>
        </el-dialog>
        <el-dialog
          :title="wo_Title"
          :visible.sync="addWo"
          class="dialog_50"
          :before-close="woClose"
        >
          <AddWorder :type="wo_type" :data="wo_data" @closeAMO="woClose" />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { findWorkOrderByPage, delWorkOrderById, closeWorkOrder } from '@/api/user.js';
import AddWorder from './AddWorkOrder';
export default {
  name: 'Workorder',
  components: {
    AddWorder
  },
  props: {

  },
  data: function() {
    return {
      searchData: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      dialogVisible: false,
      addWo: false,
      wo_Title: '提交工单',
      wo_type: 'add',
      wo_data: {},
      form: {
        customerId: '',
        orderNo: '',
        destinationWarehouseCode: '',
        status: '',
        startShipment: '',
        destinationPort: '',
        sailTime: '',
        pageNum: 1,
        pageSize: 10
      },
      formI: {},
      rulesi: {
        customerEvaluation: [
          { required: true, message: '请填写用户评价', trigger: 'blur' }
        ]
      }
    };
  },

  computed: {

  },
  watch: {

  },
  created() {
    this.findByPage();
  },
  mounted() {

  },
  methods: {
    // 搜索
    findByPage() {
      findWorkOrderByPage(this.searchData).then(result => {
        if (result.data.code == 0) {
          const data = result.data.data;
          this.tableData = data.list;
          this.total = data.total;
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    // 删除工单
    deleteL(row) {
      const id = row.id;
      delWorkOrderById({ id }).then(result => {
        if (result.data.code == 0) {
          this.$message({ message: '删除成功！', type: 'success' });
          this.findByPage();
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    // 编辑工单
    editOrder(row) {
      this.wo_data = row;
      this.woOpen('编辑工单', 'edit');
    },
    // 关闭工单
    closeL(row) {
      const id = row.id;
      closeWorkOrder({ id }).then(result => {
        if (result.data.code == 0) {
          this.$message({ message: '关闭成功！', type: 'success' });
          this.findByPage();
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    // 打开工单
    woOpen(title, type) {
      this.addWo = true;
      this.wo_Title = title;
      this.wo_type = type;
    },
    woClose() {
      this.addWo = false;
      this.findByPage();
    },
    handleClose() {
      console.log(this.$refs);
      this.$refs.formI.resetFields();
      this.dialogVisible = false;
      // this.formI.customerEvaluation=""
    },
    handleCurrentChange(val) { // 切换页数
      this.form.pageNum = val;
      this.getorderlisetinfo();
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    // 重置
    resets() {
      this.searchData = {
        pageNum: 1,
        pageSize: 10
      };
      this.total = 0;
      this.findByPage();
    }

  }
};

</script>

<style scoped>
.dialog-footer{text-align:center;}
.cursor{cursor:pointer;margin-right:10px;color:#507eff}
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(3){color:#333}
.button{min-width: 80px;cursor:pointer;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;}
.label{font-size:12px;display:inline-block;margin-right:20px}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
.transport{overflow:hidden;margin-top:44px}
.transport>div{width:50%;float:left;}
.search{margin:20px auto}
.el-form-item{margin:0;}
</style>
