<template>
  <div class="workorder">
    <div class="content">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
        <el-form-item label="订单号" prop="orderId">
          <el-select v-model="ruleForm.orderId" :disabled="hidden" :displated="hidden" clearable filterable placeholder="请选择">
            <el-option v-for="(item,i) in order_list" :key="i" :label="item.orderNo" :value="item.id">{{ item.orderNo }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传附件" prop="name">
          <el-upload
            v-if="!hidden"
            class="upload-demo addWork"
            :action="action"
            :data="params"
            :headers="headers"
            :before-upload="beforeUpload"
            :on-success="success"
            :file-list="ruleForm.fileUrls"
            :show-file-list="false"
            :on-remove="handleRemove"
          >
            <el-button slot="trigger" size="small">选取文件</el-button>
          </el-upload>
          <div class="name">
            <span v-for="(file, i) in ruleForm.fileUrls" :key="i" class="item">
              <a :href="file.absolutePath" target="_Blank">
                {{ file.fileName }}
              </a>
              <i v-if="!hidden" class="el-tag__close el-icon-close" @click.stop="fileRemove(i)" />
            </span>
          </div>
        </el-form-item>
        <el-form-item label="优先级" prop="name">
          <template>
            <el-radio v-model="ruleForm.priority" :disabled="hidden" :label="1">重要</el-radio>
            <el-radio v-model="ruleForm.priority" :disabled="hidden" :label="2">普通</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="问题描述" prop="problemDescription">
          <el-input v-model="ruleForm.problemDescription" :disabled="hidden" type="textarea" class="textarea minh-111" />
        </el-form-item>
      </el-form>
      <div style="text-align:center;margin-top:50px;">
        <el-button type="primary" round class="buttom" @click="cancel">取消</el-button>
        <el-button v-if="!hidden" type="success" round class="buttom" @click="sublime">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addWorkOrder, findOrderInfoByCustomer } from '@/api/user.js';
export default {
  name: 'Workorder',
  props: ['type', 'data'],
  data: function() {
    return {
      // 文件信息
      headers: { token: sessionStorage.token },
      action: process.env.VUE_APP_FILEURL,
      params: { fileName: '' },
      order_list: [], // 订单下拉list
      hidden: false,
      searchData: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      ruleForm: {
        businessType: 1,
        orderId: Number(this.$route.query.id) || '',
        fileUrls: [],
        priority: 1
      }, // 请求数据
      rules: { // 校验规则
        orderId: [
          { required: true, message: '请选择订单', trigger: 'change' }
        ],
        problemDescription: [
          { required: true, message: '请描述所遇到的问题', trigger: 'blur' }
        ]
      },
      form: {
        customerId: '',
        orderNo: '',
        destinationWarehouseCode: '',
        status: '',
        startShipment: '',
        destinationPort: '',
        sailTime: '',
        pageNum: 1,
        pageSize: 10
      }
    };
  },

  computed: {

  },
  watch: {

  },
  created() {
    this.init();
    this.getOrderList();
  },
  mounted() {

  },
  methods: {
    init() { // 看type是否为查看；
      const data = this.data;
      if (this.type == 'edit') {
        if (data.id || data.id == 0) {
          const fileUrls = data.fileUrls;
          for (const i in fileUrls) {
            fileUrls[i].name = fileUrls[i].fileName;
          }
          this.hidden = true;
          this.ruleForm = {
            businessType: 1,
            orderId: data.id,
            priority: 1,
            ...data
          };
        }
      }
    },
    // 请求订单list
    getOrderList() {
      findOrderInfoByCustomer().then(result => {
        if (result.data.code == 0) {
          const data = result.data.data;
          this.order_list = data;
        } else {
          this.$message({ message: result.data.msg, type: 'warning' });
        }
      }).catch(() => {
        this.$message({ message: '请求失败', type: 'warning' });
      });
    },
    // 提交工单
    sublime() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          addWorkOrder(this.ruleForm).then(result => {
            if (result.data.code == 0) {
              this.$message({ message: '提交成功', type: 'success' });
              this.cancel();
            } else {
              this.$message({ message: result.data.msg, type: 'warning' });
            }
          }).catch(() => {
            this.$message({ message: '请求失败', type: 'warning' });
          });
        }
      });
    },
    handleCurrentChange(val) { // 切换页数
      this.form.pageNum = val;
      this.getorderlisetinfo();
    },
    // 查看详情
    edit(row) {
      const id = row.id;
      this.$router.push({
        path: '/financedetails',
        query: {
          id
        }
      });
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    cancel() {
      this.$emit('closeAMO');
    },
    // 文件上传之前操作
    beforeUpload(file) {
      this.params.fileName = file.name;
    },
    success(response) { // 上传请求
      if (response.code == 200) {
        const obj = {
          ...response.data,
          name: response.data.fileName
        };
        this.ruleForm.fileUrls.push(obj);
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        this.cancel();
      } else {
        this.$message({
          message: response.msg,
          type: 'warning'
        });
      }
    },
    fileRemove(i) {
      // 删除文件
      this.ruleForm.fileUrls.splice(i, 1);
    },
    handleRemove(_file, fileList) { // 删除文件
      this.ruleForm.fileUrls = fileList;
    }
  }
};

</script>

<style scoped>
.buttom{min-width:120px;}
.textarea {width:80%;}
.title{height:30px;line-height:30px;background:#F0F0F0;margin:0;font-size:12px;color:#999999}
.title i{margin-right:10px;}
.title span{cursor:pointer;}
.title span:nth-child(5){color:#333}
.button{min-width: 80px;cursor:pointer;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 20px;}
.label{font-size:12px;display:inline-block;margin-right:20px}
.select{background:#FC6C3F;color:#fff;border-color:#FC6C3F !important;}
.transport{overflow:hidden;margin-top:44px}
.transport>div{width:50%;float:left;}
.search{margin:20px auto}
.name {
  display: flex;
  align-items: center;
  margin:5px 0px 0;
  overflow: auto;
}
.item {
  margin-bottom: 0px;
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 10px;
}
.item a {
  text-decoration-line: none;
  color: #007aff;
}
.names {
  display: initial;
}
</style>
