import require from '@/utils/require';
import { network, auto } from '@/config/geteway';
// 登录
export const login = function(params) {
  return require({
    url: `${network}/${auto}/login`,
    method: 'POST',
    params
  });
};
// 退出
export const sigin = function(params) {
  return require({
    url: `${network}/${auto}/signout`,
    method: 'GET',
    params
  });
};
// 注册
export const register = function(data) {
  return require({
    url: `${network}/${auto}/customer/customerRegister`,
    method: 'POST',
    data
  });
};
// 注册
export const registerNew = function(data) {
  return require({
    url: `${network}/jayudCrm/crmCustomer/registerNanj`,
    method: 'POST',
    data
  });
};
// 获取验证码
export const verification = function(data) {
  return require({
    url: `${network}/${auto}/phonemessages/sendMessage`,
    method: 'POST',
    data
  });
};
// 修改密码验证
export const customerVerify = function(data) {
  return require({
    url: `${network}/${auto}/customer/customerVerify`,
    method: 'POST',
    data
  });
};

// 确认修改
export const customerUpdatePwd = function(data) {
  return require({
    url: `${network}/${auto}/customer/customerUpdatePwd`,
    method: 'POST',
    data
  });
};

export const user = function(params) {
  return require({
    url: `/api/nodes/show.json`,
    method: 'GET',
    params
  });
};
// 用户信息
export const findMyAccount = function(data) {
  return require({
    url: `${network}/${auto}/customer/findMyAccount`,
    method: 'POST',
    data
  });
};
// 编辑用户
export const updateCustomer = function(data) {
  return require({
    url: `${network}/${auto}/customer/updateCustomer`,
    method: 'POST',
    data
  });
};

// 工单系统-分页
export const findWorkOrderByPage = function(data) {
  return require({
    url: `${network}/${auto}/workorder/findWorkOrderByPage`,
    method: 'POST',
    data
  });
};
export const delWorkOrderById = function(data) { // 删除工单
  return require({
    url: `${network}/${auto}/workorder/delWorkOrderById`,
    method: 'POST',
    data
  });
};
export const closeWorkOrder = function(data) { // 关闭工单
  return require({
    url: `${network}/${auto}/workorder/closeWorkOrder`,
    method: 'POST',
    data
  });
};
export const findOrderInfoByCustomer = function(data) { // 订单下拉list
  return require({
    url: `${network}/${auto}/orderinfo/findOrderInfoByCustomer`,
    method: 'POST',
    data
  });
};
export const addWorkOrder = function(data) { // 添加订单
  return require({
    url: `${network}/${auto}/workorder/addWorkOrder`,
    method: 'POST',
    data
  });
};
export const updatePasswords = function(data) { // 修改密码
  return require({
    url: `${network}/${auto}/customer/updatePasswords`,
    method: 'POST',
    data
  });
};

export const findCurrAccountBalance = function(data) { // web端查询当前登录账户余额
  return require({
    url: `${network}/${auto}/accountbalance/findCurrAccountBalance`,
    method: 'POST',
    data
  });
};

export const customerPay = function(data) { // 账户余额-立即充值(客户充值)
  return require({
    url: `${network}/${auto}/tradingrecord/customerPay`,
    method: 'POST',
    data
  });
};

export const findTradingRecordByCz = function(data) { // 账户余额-充值记录（查询充值记录）
  return require({
    url: `${network}/${auto}/tradingrecord/findTradingRecordByCz`,
    method: 'POST',
    data
  });
};

export const findTradingRecord = function(data) { // 账户余额-交易记录（查询充值、支付记录）
  return require({
    url: `${network}/${auto}/tradingrecord/findTradingRecord`,
    method: 'POST',
    data
  });
};

// 当月汇率信息提示
export const selectCurrencyRateByMoth = function(data) { // 账户余额-交易记录（查询充值、支付记录）
  return require({
    url: `${network}/${auto}/orderinfo/selectCurrencyRateByMoth`,
    method: 'GET',
    params: data
  });
};

